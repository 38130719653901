import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {styles} from '../../../styles';
import Grid from '@material-ui/core/Grid';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import CheckIcon from '@material-ui/icons/Check';
import Moment from 'react-moment';
import {Link as RouteLink} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {withTranslation} from "../../../i18n/translator.provider";

const QuestionarListItem = ({classes, questionar: {schedule_due_date, object_id, schedule_id, title, schedule_fulfilled, schedule_fulfillment_date, isPassed, isToday}, translations: {LBL_QUESTIONAR}}) => {
    const displayQuestionar = ({schedule_due_date, schedule_fulfilled, isToday, isPassed}) => {
        return (
            <Grid container direction={'column'}>
                <Grid xs={12} item>
                    <Typography variant={'subtitle1'} className={[classes.strong, classes.yellowColor].join(' ')}>{LBL_QUESTIONAR}</Typography>
                </Grid>
                <Grid xs={12} item>
                    <Grid container direction={'row'} justify={'space-between'}>
                        <Grid item>
                            <Grid container>
                                <Typography variant={'body2'} className={[isToday ? classes.strong : ''].join(' ')}
                                            style={{textTransform: 'capitalize'}}>
                                    <Moment format="DD MMM YYYY">{schedule_due_date}</Moment>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {displayQuestionarStatus(schedule_fulfilled, isToday, isPassed)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const displayQuestionarStatus = (schedule_fulfilled, isToday, isPassed) => {
        if (schedule_fulfilled) {
            return <CheckIcon className={classes.successColor} fontSize={'small'}/>;
        } else if (isPassed) {
            return <BlockIcon color={'error'} fontSize={'small'}/>;
        } else if (isToday) {
            return <NotificationIcon color={'primary'} fontSize={'small'}/>;
        } else {
            return <ScheduleIcon className={classes.hintColor} fontSize={'small'}/>;
        }
    };

    return (
        <React.Fragment>
            {isPassed || isToday ?
                <ListItem button component={RouteLink}
                          className={isToday ? classes.leftBorderYellow : ''}
                          to={{
                              pathname: `/questionar/`,
                              state: {
                                  object_id: object_id,
                                  schedule_id: schedule_id,
                                  schedule_fulfilled: schedule_fulfilled,
                                  schedule_due_date: schedule_due_date,
                                  isPassed: isPassed
                              }
                          }}>
                    <ListItemText primary={displayQuestionar({
                        title,
                        schedule_due_date,
                        schedule_fulfilled,
                        schedule_fulfillment_date,
                        isPassed,
                        isToday
                    })}/>
                </ListItem>
                :
                <ListItem>
                    <ListItemText primary={displayQuestionar({
                        title,
                        schedule_due_date,
                        schedule_fulfilled,
                        schedule_fulfillment_date,
                        isPassed,
                        isToday
                    })}/>
                </ListItem>
            }
        </React.Fragment>
    );
};

QuestionarListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    questionar: PropTypes.object.isRequired
};

export default withTranslation([
    'LBL_QUESTIONAR'
])(withStyles(styles)(QuestionarListItem));
