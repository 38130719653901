import React, {Component} from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultLayout from '../../hoc/layouts/default';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {compose, graphql} from 'react-apollo';
import QuestionarQuery from './questionar.graphql';
import CompileMutation from './save-questionar.graphql';
import GetAnswersQuery from './get-answers.graphql';
import {Questionar} from '../../../classes/questionar.class';
import CircularProgress from '@material-ui/core/CircularProgress';
import QSlider from '../../ui/QSlider/QSlider';
import QRadio from '../../ui/QRadio/QRadio';
import QChecks from '../../ui/QChecks/QChecks';
import QInput from '../../ui/QInput/QInput';
import QText from '../../ui/QText/QText';
import withApollo from 'react-apollo/withApollo';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import {getPending, setPending, updateQuestionarState} from '../../../services/offline-mutations.service';
import Moment from 'react-moment';
import DashboardQuery from '../calendar/patient.query.graphql';
import {fetchPolicy} from "../../../services/offline-manager";
import {withTranslation} from "../../../i18n/translator.provider";
import {withRouter} from "react-router-dom";

class QuestionarComponent extends Component {
    state = {
        questionar: null,
        toast: false
    };

    handleChange = (component, value) => {
        this.setState(prevState => ({
            ...prevState,
            questionar: {
                ...prevState.questionar,
                questions: prevState.questionar.questions.map(
                    (q) => {
                        if (component.id === q.component.id) {
                            if (component.type === 'QChecks') {
                                return {
                                    ...q,
                                    component: {
                                        ...q.component,
                                        input: {
                                            ...q.component.input,
                                            checks: q.component.input.checks.map(
                                                (check) => {
                                                    if (check.id === value) {
                                                        check.value = !check.value;
                                                    }
                                                    return check;
                                                }
                                            )
                                        }
                                    }
                                };
                            }
                            return {
                                ...q,
                                component: {
                                    ...q.component,
                                    input: {
                                        ...q.component.input,
                                        value: value
                                    }
                                }
                            };
                        }
                        return q;
                    }
                )
            }
        }));
    };

    renderQComponent = (component) => {
        switch (component.type) {
            case 'QSlider':
                return <QSlider component={component} onChange={this.handleChange}/>;
            case 'QRadio':
                return <QRadio component={component} onChange={this.handleChange}/>;
            case 'QChecks':
                return <QChecks component={component} onChange={this.handleChange}/>;
            case 'QInput':
                return <QInput component={component} onChange={this.handleChange}/>;
            case 'QText':
                return <QText component={component} onChange={this.handleChange}/>;
            default:
                return;
        }
    };

    submitQuestionar = () => {
        const {client, user: {key, code}, location: {state: {schedule_id}}} = this.props;
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 2); // Current first month day
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1); // Current last month day
        const dashboardQuery = {
            query: DashboardQuery,
            variables: {
                schedules_filters: [{
                    q: 'schedule_due_date',
                    op: 'dt',
                    values: [firstDay, lastDay]
                }],
                assumed_filters: [{
                    q: 'assumed_date',
                    op: 'dt',
                    values: [firstDay, lastDay]
                }]
            }
        };
        this.setState({
            answers: this.state.questionar.questions.map(
                ({question_id, question_type, component: {input: {value}}}) => {
                    let q = {
                        question_id: question_id
                    };
                    if (question_type === 'text') {
                        q.answer_type = 'comment';
                        q.answer_comment = value;
                    } else {
                        q.answer_type = 'number';
                        q.answer_number = parseInt(value);
                    }
                    return q;
                }
            )
        }, () => {
            const mutationId = `mutation-${key}-${schedule_id}`;
            const _answers = this.state.answers.map((a) => ({
                answer_id: undefined,
                __typename: 'Answer',
                ...a
            }));
            const compileMutation = {
                mutation: CompileMutation,
                variables: {
                    patient: {
                        id: key,
                        code: code
                    },
                    schedule_id: schedule_id,
                    answers: this.state.answers
                },
                fetchPolicy: 'no-cache',
                optimisticResponse: {
                    Compile: {
                        compiler_id: key,
                        answers: _answers,
                        schedule: {
                            schedule_id: schedule_id,
                            schedule_fulfilled: true,
                            __typename: 'Schedule',
                            compiled: {
                                answers: _answers,
                                __typename: 'Compiled'
                            }
                        },
                        __typename: 'Compiled'
                    },
                    __optimistic: true
                },
                update: updateQuestionarState(mutationId, dashboardQuery, this._compiled)
            };
            // REGISTRY THE MUTATION IN THE CACHE
            setPending(getPending().concat({id: mutationId, params: compileMutation, mutation: CompileMutation}));
            client.mutate(compileMutation).then(
                () => {
                    this._compiled();
                },
                ({graphQLErrors: [{message}]}) => {
                    setPending(getPending().filter(mutation => mutation.id !== mutationId));
                    this.setState({
                        toast: true,
                        toastMsg: message
                    });
                });
        });
    };

    _compiled = () => {
        this.setState({
            toast: true,
            toastMsg: 'Questionar successfully saved'
        }, () => {
            this.props.history.goBack();
        });
    };

    render() {
        const {classes, title, loading, history, location: {state: {schedule_due_date}}, translations: {CTA_SAVE_SEND}} = this.props;
        const {questionar, toast, toastMsg} = this.state;
        if (loading) {
            return (
                <DefaultLayout title={title}>
                    <Grid container direction={'row'} justify={'center'}>
                        <CircularProgress className={classes.mt4} size={24} color={'primary'}/>
                    </Grid>
                </DefaultLayout>
            );
        }
        return (
            <DefaultLayout title={title}>
                <div className={classes.mt3}>
                    <Grid className={classes.mb1} container justify={'center'}>
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <Grid container alignItems={'center'}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.hintColor}
                                                onClick={() => history.goBack()} color="inherit"
                                                aria-label="Back">
                                        <BackIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                                        <Typography align={'center'} variant={'h6'} className={classes.hintColor}>
                                            {questionar ? questionar.questionar_code : ''}
                                        </Typography>
                                        <Typography align={'center'} variant={'body2'}
                                                    className={[classes.hintColor, classes.ml1].join(' ')}>
                                            (<Moment format={'DD-MM-YYYY'}>{schedule_due_date}</Moment>)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'}>
                        <Grid item xs={12} md={10} lg={8} xl={6}>
                            <Paper className={[classes.m1, classes.py2, classes.oh].join(' ')}>
                                {questionar && questionar.questions.map(
                                    ({component, question_id}, index) => {
                                        return (
                                            <React.Fragment key={question_id}>
                                                <Grid container direction={'row'} justify={'center'}
                                                      className={[classes.py2, classes.px3, index % 2 === 1 ? classes.dividerColor : ''].join(' ')}>
                                                    <Grid item xs={12}>
                                                        {this.renderQComponent(component)}
                                                    </Grid>
                                                </Grid>
                                                {index !== questionar.questions.length - 1 ? <Divider/> : ''}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                                <Grid container direction={'row'} justify={'center'}>
                                    <Grid item xs={8}>
                                        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                            {this.state.questionar && !this.state.questionar.disabled &&
                                            <Button color={'primary'} variant={'contained'} size={'medium'}
                                                    style={{borderRadius: '35px', boxShadow: 'none'}}
                                                    onClick={this.submitQuestionar}
                                                    className={[classes.my3, classes.w75].join(' ')}>
                                                {CTA_SAVE_SEND}
                                            </Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={toast}
                    autoHideDuration={6000}
                    onClose={() => this.setState({toast: false})}
                    ContentProps={{
                        'aria-describedby': 'success-message',
                    }}
                    message={<span id="success-message">{toastMsg}</span>}
                />
            </DefaultLayout>
        );
    }


    componentDidUpdate(prevProps, prevState, snapshot): void {
        const {questionar, location: {state: {schedule_fulfilled, isPassed}}} = this.props;
        if (!prevState.questionar && questionar) {
            this.setState({
                questionar: questionar
            }, () => {
                if (schedule_fulfilled || isPassed) {
                    questionar.deactivate(true);
                    if (schedule_fulfilled) this.fillQAnswers();
                }
            });
        }
    }

    fillQAnswers = () => {
        const {location: {state: {schedule_id}}, client} = this.props;
        client.query({
            query: GetAnswersQuery,
            variables: {
                schedules_filters: [{
                    q: 'schedule_id',
                    op: 'eq',
                    value: schedule_id
                }]
            },
            fetchPolicy: fetchPolicy('cache-first')
        }).then(
            ({data: {Patient: {schedules}}}) => {
                schedules[0].compiled.answers.forEach(
                    ({answer_comment, answer_number, question_id}) => {
                        this.setState((prevState) => {
                            return {
                                ...prevState,
                                questionar: {
                                    ...prevState.questionar,
                                    questions: prevState.questionar.questions.map((q) => {
                                        if (q.question_id === question_id) {
                                            if (q.question_type === 'text') {
                                                q.question_comment = answer_comment;
                                                q.component.input.value = answer_comment;
                                            } else if (q.question_type === 'enum') {
                                                q.answer_number = answer_number;
                                                q.component.input.value = answer_number.toString();
                                            } else {
                                                q.question_number = answer_number;
                                                q.component.input.value = answer_number;
                                            }
                                        }
                                        return q;
                                    })
                                }
                            };
                        });
                    }
                );
            }
        );
    };
}


const mapResultsToProps = ({data}) => {
    if (!data.Questionar)
        return {
            loading: data.loading,
        };
    return {
        questionar: new Questionar(data.Questionar)
    };
};

const mapPropsToOptions = ({location: {state: {object_id}}}) => {
    return {
        variables: {
            id: {
                id: object_id
            }
        },
        fetchPolicy: fetchPolicy()
    };
};

export default compose(
    graphql(QuestionarQuery, {
        options: mapPropsToOptions,
        props: mapResultsToProps
    }),
    withTranslation([
        'CTA_SAVE_SEND'
    ]),
    withApollo,
    withRouter,
    withStyles(styles)
)(QuestionarComponent);

