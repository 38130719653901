import React from 'react';
import {styles} from '../../../styles';
import withStyles from '@material-ui/core/styles/withStyles';
import DefaultLayout from '../../hoc/layouts/default';
import QuestionarsHistory from '../../ui/QuestionarsHistory/QuestionarsHistory';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {E_QUESTIONAR} from '../../../constants';
import {graphql} from 'react-apollo';
import QuestionarsQuery from './questionars.graphql';
import moment from 'moment';
import _ from 'lodash';
import {fetchPolicy} from "../../../services/offline-manager";
import {withTranslation} from "../../../i18n/translator.provider";

const QuestionarsComponent = ({classes, title, loading, questionars, translations: {TITLE_QUESTIONARS_HISTORY}}) => {
    return (
        <DefaultLayout title={title}>
            <Grid container justify={'center'}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Grid container direction={'column'}>
                        <Typography align={'center'} variant={'h6'}
                                    className={[classes.hintColor, classes.my1].join(' ')}>
                            {TITLE_QUESTIONARS_HISTORY}
                        </Typography>
                        <Grid item xs={12}>
                            <Grid container direction={'row'} justify={'center'}>
                                {loading && <CircularProgress size={28} className={classes.mt4}/>}
                                {!loading && <QuestionarsHistory questionars={questionars}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DefaultLayout>
    );
};

const mapResultsToProps = ({data}) => {
    if (!data.Patient)
        return {
            loading: data.loading,
        };
    let {schedules} = data.Patient;
    const today = moment().format('DD/MM/YYYY');
    schedules = schedules.map((schedule) => {
        const {object: {questionar_code}, schedule_due_date} = schedule;
        const dueDate = moment(schedule_due_date).format('DD/MM/YYYY');
        const isToday = dueDate === today;
        const isPassed = moment(schedule_due_date).isBefore(moment()) && !isToday;
        return {
            title: questionar_code,
            isPassed: isPassed,
            isToday: isToday,
            ...schedule
        };
    });
    if (!schedules.length) {
        return {
            questionars: []
        };
    }
    return {
        questionars: _.orderBy(schedules, ['schedule_due_date'], 'desc')
    };
};

const mapPropsToOptions = () => {
    return {
        variables: {
            schedules_filters: [
                {
                    q: 'object_subtype',
                    op: 'eq',
                    value: E_QUESTIONAR
                },
                {
                    "q": "schedule_due_date",
                    "op": "le",
                    "value": moment().format('YYYY-MM-DD')
                }
            ]
        },
        fetchPolicy: fetchPolicy()
    };
};

QuestionarsComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default graphql(QuestionarsQuery, {
    props: mapResultsToProps,
    options: mapPropsToOptions
})(withTranslation([
    'TITLE_QUESTIONARS_HISTORY'
])(withStyles(styles)(QuestionarsComponent)));
